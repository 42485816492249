
export enum RoomTypes {
    SPY = 'Spy',
    WHO_AM_I = 'WhoAmI'
}

export interface CreateRoomRequest {
    type: RoomTypes,
    roomName: string,
    ownerId: string,
    data: string
}

export interface UserInterface {
    id: string,
    username: string,
}
export interface RoomResponse {
    id: string
    roomName: string,
    type: RoomTypes,
    data: string,
    ownerId: string,
    users: Array<UserInterface>,
}
