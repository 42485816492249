import {
  Box,
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { RoomTypes } from "../types/api/Room";
import { ChangeEvent, useState } from "react";
import { getRoomRandomName } from "../utils/helpers";
import { API } from "../api";
import { getUUIDFormLocalStorage } from "../utils/local-storage";
import { SpyGameDataDefaults } from "../components/games/spy/SpyGame";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LinkIcon from "@mui/icons-material/Link";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function CreateRoom() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const roomType = searchParams.get("type") as RoomTypes;

  const [roomName, setRoomName] = useState(getRoomRandomName());
  const [userName, setUserName] = useState("");

  const userNameInputChange = (event: ChangeEvent<HTMLInputElement>) =>
    setUserName(event.target.value);

  const createRoom = async () => {
    const uuid = await getUUIDFormLocalStorage();
    const createdRoom = await API.room.createRoom({
      type: roomType,
      roomName,
      ownerId: uuid,
      data: JSON.stringify(SpyGameDataDefaults),
    });

    navigate(`/room/${createdRoom.id}`);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box>
        <Typography component="h1" variant="h5">
          Create New Game: Who am I?
        </Typography>

        <Typography>Room Name: {roomName}</Typography>
      </Box>
      <Card style={{ backgroundColor: "#CEEAFF" }}>
        <CardContent>
          {/* <TextField
            margin="normal"
            required
            fullWidth
            id="userName"
            label="Your Name"
            name="userName"
            autoFocus
          /> */}

          <Box>
            <CardContent
              style={{ padding: 0 }}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <AccountCircleIcon
                sx={{
                  mr: 1,
                }}
              />
              <Typography variant="h6">Your name:</Typography>
            </CardContent>

            <TextField
              autoFocus
              required
              fullWidth
              placeholder="Normal"
              style={{ backgroundColor: "#fff" }}
              value={userName}
              onChange={userNameInputChange}
            />
          </Box>

          <Box>
            <CardContent
              style={{ padding: 0 }}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <LinkIcon
                sx={{
                  mr: 1,
                }}
              />
              <Typography variant="h6">Game link:</Typography>
            </CardContent>

            <TextField
              required
              fullWidth
              placeholder="Normal"
              style={{ backgroundColor: "#fff" }}
              InputProps={{
                disableUnderline: true,
                endAdornment: <ContentCopyIcon />,
              }}
            />
          </Box>
          {/* <TextField
            margin="normal"
            required
            fullWidth
            id="userName"
            name="userName"
            autoFocus
            value={userName}
            onChange={userNameInputChange}
          /> */}
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            onClick={createRoom}
          >
            <Typography color={"#fff"} fontWeight={"900"}>
              Create New Game
            </Typography>
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
}

export default CreateRoom;
