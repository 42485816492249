import { Button, Container, Typography } from "@mui/material";
import PlayersList from "../../PlayersList";
import { RoomResponse } from "../../../types/api/Room";
import { useMemo } from "react";
import { getUUIDFormLocalStorage } from "../../../utils/local-storage";
import { shuffleArray } from "../../../utils/helpers";
import { getSpyLocations } from "./helpers";
import socket from "../../../socket";
import { SocketEventsEnum } from "../../../constants";
import SpyCard from "./SpyCard";

interface RoomProps {
  roomInfo: RoomResponse;
  roomUpdateEvent: () => void;
}

interface RolesProps {
  userId: string;
  role: string;
}

interface GameDataInterface {
  numberOfSpies: number;
  categories: string;
  timer: number;
  roles: RolesProps[];
  showRoles: boolean;
}


export const SpyGameDataDefaults = {
  numberOfSpies: 1,
  categories: "*",
  timer: 0,
  roles: [],
  showRoles: false
}

function SpyGame({roomInfo, roomUpdateEvent}: RoomProps) {
  const users = useMemo(() => roomInfo?.users, [roomInfo?.users]);
  const gameData: GameDataInterface = useMemo(() => JSON.parse(roomInfo?.data), [roomInfo?.data]);
  const isOwner = useMemo(() => {
    const uuid = getUUIDFormLocalStorage();

    return uuid === roomInfo?.ownerId;
  }, [roomInfo?.ownerId]);


  const startGame = async () => {
    const shufflePlayers = shuffleArray(users);
    const location = getSpyLocations(gameData.categories);

    const newGameData = { ...gameData };
    for(let i = 0; i < shufflePlayers.length; i++){
      newGameData.roles[i] = {
        userId: shufflePlayers[i].id,
        role: i < gameData.numberOfSpies ? 'spy': location
      }
    }
    newGameData.showRoles = true;

    console.log(`Shuffled players game data: ${JSON.stringify(newGameData)}`);

    const res = await socket.emit(
      SocketEventsEnum.UPDATE_ROOM_STATE_EVENT,
      roomInfo.id,
      { ...roomInfo, data: JSON.stringify(newGameData) }
    );

    console.log(res);
  }

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5">
        SpyGame
      </Typography>

      <PlayersList users={users}/>

      {isOwner && <Button onClick={startGame} variant="contained">Start game</Button>}


      {gameData?.showRoles && <SpyCard role={gameData.roles.find(el => el.userId === socket.id)?.role as string}/>}
    </Container>
  );
}

export default SpyGame;
