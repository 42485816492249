import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: '#2BA6FF',
    },
    secondary: {
      main: '#242424',
    },
    text: {
      primary: '#242424',
    },
  },
});

export default theme;