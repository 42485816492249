import {
  Container,
  InputBase,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { GamesList } from "../components";

function Home() {
  return (
    <Container component="main" maxWidth="xs">
      <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
        Let's play together!
      </Typography>
      <InputBase
        placeholder="Search..."
        sx={{ width: "100%", mt: 2 }}
        startAdornment={<SearchIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />}
      />
      <GamesList />
    </Container>
  );
}

export default Home;
