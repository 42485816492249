import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import WhoAmIMan from "../assets/who-am-i-man.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { GAMES_INFO_DATA } from "../constants/game-info";
import { getGameTypeFromUrl } from "../utils/helpers";
import {
  EstimatedDuration,
  LoadingSpinner,
  NumberOfPlayers,
} from "../components";

function GameInfo() {
  const { type: gameType } = useParams();
  const navigate = useNavigate();

  const gameInfo = useMemo(() => {
    return GAMES_INFO_DATA.find(
      (el) => el.type === getGameTypeFromUrl(gameType as string)
    );
  }, [gameType]);

  useEffect(() => {
    console.log(gameType);
  }, [gameType]);

  useEffect(() => {
    console.log(gameInfo);
  }, [gameInfo]);

  const createRoom = () => {
    navigate(`/room/create?type=${gameType}`);
  };

  if (!gameInfo) return <LoadingSpinner />;

  return (
    <Card>
      <CardMedia component="img" height="114" image={WhoAmIMan} alt="SpyLogo" />
      <CardContent>
        <Typography variant="h5" fontWeight={"800"}>
          {gameInfo?.type}
        </Typography>
        <EstimatedDuration duration={gameInfo.estimatedTime} />
        <NumberOfPlayers
          min={gameInfo.minNumberOfPlayers}
          max={gameInfo.maxNumberOfPlayers}
        />
      </CardContent>

      <CardContent>
        <Typography variant="body1">{gameInfo.about}</Typography>
      </CardContent>

      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ mb: 3 }}>
            Let's start!
          </Typography>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <Typography variant="body1">
                1. Create a new room to start a game.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                2. Make sure all your friends join the created room.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">3. Enjoy playing!</Typography>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            onClick={createRoom}
          >
            <Typography color={"#fff"} fontWeight={"900"}>
              Create room
            </Typography>
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

export default GameInfo;
