import { Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import socket from "../socket";
import { SocketEventsEnum } from "../constants";
import { useNavigate } from "react-router-dom";

interface DeleteRoomProps {
  roomId: string;
}

function DeleteRoom({ roomId }: DeleteRoomProps) {
  const navigate = useNavigate();

  const deleteRoom = async () => {
    const res = await socket.emit(SocketEventsEnum.DELETE_ROOM_EVENT, roomId);
    console.log('delete room', res)

    navigate('/')
  };

  return (
    <>
      <Button variant="outlined" startIcon={<DeleteIcon />} onClick={deleteRoom}>
        Delete
      </Button>
    </>
  );
}

export default DeleteRoom;
