import { CreateRoomRequest, RoomResponse } from "../../types/api/Room";
import { RestApi } from "../RestApi"

export const createRoom = async (body: CreateRoomRequest): Promise<RoomResponse> => {
    const res = await RestApi.post('room', body);
    return res.data as RoomResponse;
}

export const getRoomData = async (roomId: string): Promise<RoomResponse> => {
    const res = await RestApi.get(`room/${roomId}`);
    return res.data as RoomResponse;
}
