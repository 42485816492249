import { RoomResponse } from "../types/api/Room";

export const setRoomCreationDataInLocalStorage = (data: RoomResponse) => {
    localStorage.setItem(`${data.id}-creation`, JSON.stringify(data));
};
  
export const getRoomCreationDataFormLocalStorage = (id: string): RoomResponse => {
    const savedData = localStorage.getItem(`${id}-creation`);

    return JSON.parse(savedData as string) as RoomResponse;
};
  

export const setUUIDInLocalStorage = (uuid: string) => {
    localStorage.setItem('uuid', JSON.stringify(uuid));
};
  
export const getUUIDFormLocalStorage = (): string => {
    const savedData = localStorage.getItem('uuid');

    return JSON.parse(savedData as string);
};
  