import { Card, CardActionArea, CardContent, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PlaceIcon from '@mui/icons-material/Place';
import PersonIcon from '@mui/icons-material/Person';

interface SpyCardProps {
  role: string;
}

let timer: NodeJS.Timer;

function SpyCard({ role }: SpyCardProps) {
    const [showSpy, setShowSpy] = useState(true);

    const handleClick = () => {
      setShowSpy(val => !val);
      clearTimeout(timer);
    };

    useEffect(() => {
        if (showSpy !== null) {
          timer = setTimeout(() => {
            setShowSpy(false);
          }, 7*1000);
        }
    
        return () => clearTimeout(timer);
      }, [showSpy]);
  
  return (

    <Card>
      <CardActionArea onClick={handleClick}>
        <CardContent>
          <Typography variant="h5" component="div" textAlign="center">
            Click to reveal
          </Typography>
          {showSpy && (
            <>
                <IconButton color="primary">
                    {role === 'spy' ? <PersonIcon fontSize="large" /> : <PlaceIcon fontSize="large" />}
                </IconButton>

                <Typography variant="h5" component="div" textAlign="center">
                    {role === 'spy' ? "You are SPY" : role}
                </Typography>
            </>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default SpyCard;
