import axios, { CreateAxiosDefaults } from "axios";

const config: CreateAxiosDefaults<any> = {
  baseURL: process.env.NEXT_PUBLIC_API_HOST || "http://localhost:5000/v1",
  headers: {},
};

const API = axios.create(config);

// API.interceptors.request.use(
//   function (config) {
//     // Do something before request is sent
//     console.log(config)
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

// API.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     console.log(config)
//     return response;
//   },
//   function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error);
//   }
// );

export const RestApi = API;
