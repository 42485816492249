import { RoomTypes } from "../types/api/Room";
import SpyLogo from "../assets/spy-logo.svg";
import WhoAmILogo from "../assets/who-am-i-logo.svg";

export const GAMES_INFO_DATA = [
  {
    type: RoomTypes.SPY,
    logo: SpyLogo,
    estimatedTime: "5 min",
    maxNumberOfPlayers: undefined,
    minNumberOfPlayers: 3,
    about:
      '"Spy" is a fast-paced card game of deduction and bluffing. Players ask questions to uncover the spy among them, who, in turn, tries to guess the secret location. Quick and engaging, it\'s a social game that keeps everyone on their toes.',
    rules: [
      "Players take turns asking each other questions related to the location on their card.",
      "The goal for non-spies is to figure out who the spy is, and the goal for the spy is to guess the location.",
      "The spy must try to blend in with the group and answer questions without revealing that they don't know the location.",
      "If a player accuses another of being the spy, a vote may be taken. If the majority agrees, the accused player reveals their card.",
      "The game continues until the timer runs out, or the spy is identified. If the spy correctly identifies the location, they win; otherwise, the non-spies win.",
    ],
    winning: [
      "If the spy is correctly identified, the non-spies win.",
      "If the spy correctly identifies the location, the spy wins.",
    ],
    variations: [
      "Locations: The game can be played with various themed decks containing different locations.",
      "Roles: Some versions of the game include additional roles with special abilities.",
    ],
  },
  {
    type: RoomTypes.WHO_AM_I,
    logo: WhoAmILogo,
    estimatedTime: "10 min",
    minNumberOfPlayers: 2,
    maxNumberOfPlayers: undefined,
    about:
      "Who Am I?\" is a guessing game where one person selects a famous person, and the other participants try to figure out who it is by asking yes-or-no questions. The goal is to guess the identity of the chosen person within a certain number of questions. It's a fun and interactive game that challenges players' knowledge and deduction skills.",
    rules: [
      "Players take turns being the guesser.",
      "The guesser randomly selects a name from the shuffled pile without looking at it and sticks it to their forehead or shows it to the group.",
      "The guesser then starts asking yes-or-no questions to figure out their identity.",
      'The other players can only respond with "yes," "no," or "I don\'t know."',
      "The guesser continues asking questions until they correctly guess the person or decide to give up.",
      "Once a guess is made, the next player becomes the guesser, and the process repeats.",
    ],
    winning:
      "The game can be played for a set number of rounds, and the player with the most correct guesses at the end wins. Alternatively, you can play for a certain amount of time, and the person with the most correct guesses at the end of that time is the winner",
    variations: [
      "Time Limit: Set a time limit for each round to keep the game fast-paced.",
      "Categories: Choose a specific category for the famous people, such as actors, historical figures, or fictional characters.",
      "Team Play: Divide players into teams and take turns guessing within the team.",
    ],
  },
];
