import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GameInfo, CreateRoom, Home, NotFound, Room, RoomInfo } from "../pages";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/game/:type" element={<GameInfo />}></Route>
        <Route path="/room">
          <Route index element={<RoomInfo />} />
          <Route path="create" element={<CreateRoom />} />
          <Route path=":id" element={<Room />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
