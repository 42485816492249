import { Container, Typography } from "@mui/material";

function RoomInfo() {
  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5">
        RoomInfo
      </Typography>
    </Container>
  );
}

export default RoomInfo;
