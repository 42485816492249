import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useNavigate } from "react-router-dom";
import { RoomTypes } from "../../types/api/Room";
import { getGameUrlName } from "../../utils/helpers";
import { GAMES_INFO_DATA } from "../../constants/game-info";

function GamesList() {
  const navigate = useNavigate();

  const selectedGame = (gameType: RoomTypes) => {
    navigate(`game/${getGameUrlName(gameType)}`);
  };
  return (
    <Grid container spacing={2} sx={{ mt: 4 }}>
      {GAMES_INFO_DATA.map((game, index) => (
        <Grid item xs={6} key={index}>
          <Card>
            <CardActionArea onClick={() => selectedGame(game.type)}>
              <CardMedia
                component="img"
                height="194"
                image={game.logo}
                alt={`${game.type} Logo`}
              />
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" component="div">
                  {game.type}
                </Typography>
                <PlayArrowIcon />
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default GamesList;
