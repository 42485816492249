import { Typography } from "@mui/material";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";

interface Props {
  min: number;
  max: number | undefined;
}

function NumberOfPlayers({ min, max }: Props) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <GroupOutlinedIcon
        color="primary"
        sx={{
          mr: 1,
        }}
      />
      <Typography variant="body1">
        {min}
        {max ? ` - ${max}` : "+"} players
      </Typography>
    </div>
  );
}

export default NumberOfPlayers;
