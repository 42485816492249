import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { UserInterface } from '../types/api/Room';


interface PlayersListProps{
    users: UserInterface[]
}

function PlayersList({ users }: PlayersListProps) {  
  return (
    <List>
        <ListItemText>
            <Typography variant="h5">Players number: {users.length}</Typography>
        </ListItemText>
        {users.map((user) => (
            <ListItem key={user.id}>
            <ListItemAvatar>
                <Avatar>{user.username}</Avatar>
            </ListItemAvatar>
            <ListItemText>
                <Typography variant="subtitle1">{user.username}</Typography>
                <Typography variant="subtitle1">{user.id}</Typography>
            </ListItemText>
            </ListItem>
        ))}
    </List>

  );
}

export default PlayersList;
