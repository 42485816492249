export const SPY_LOCATIONS = [
    "School",
    "Hospital",
    "Ocean",
    "Treasure Island",
    "Plane",
    "Train",
    "Ancient Greece",
    "Military Base",
    "Atlanta",
    "Underwater Research Facility",
    "Space Station",
    "Carnival",
    "Haunted Mansion",
    "Spy Headquarters",
    "Secret Laboratory",
    "Double Agent's Safe House",
    "Jungle",
    "Library",
    "Amusement Park",
    "Desert Oasis",
    "Pirate Ship",
    "Art Museum",
    "Mount Everest",
    "Subway Station",
    "Moon Base",
    "Castle",
    "Wild West Town",
    "Underground Cave",
    "International Space Station",
    "Rainforest",
    "White House",
    "Volcano Lair",
    "Arctic Research Station",
    "Grand Canyon",
    "Bank Vault",
    "Japanese Tea Garden",
    "Eiffel Tower",
    "Pyramid",
    "Robot Factory",
    "Underworld Realm",
    "Time Travel Agency",
    "Floating City",
    "Venice Canals",
    "Amazon Rainforest",
    "Oil Rig",
    "Sunken City",
    "Space Colony",
    "Hidden Temple"
  ]
  