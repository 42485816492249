import { useEffect } from "react";
import Router from "./router/Router";
import { setUUIDInLocalStorage } from "./utils/local-storage";
import { nanoid } from "nanoid";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./styles/theme";
import { Header } from "./components";

function App() {
  useEffect(() => {
    const uuid = nanoid();
    setUUIDInLocalStorage(uuid);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Router />
    </ThemeProvider>
  );
}

export default App;
