export const SocketEventsEnum = Object.freeze({
    CONNECTED_EVENT: "connected",
    DISCONNECT_EVENT: "disconnect",

    CREATE_ROOM_EVENT: "createRoom",
    DELETE_ROOM_EVENT: "deleteRoom",
    JOIN_ROOM_EVENT: "joinRoom",
    UPDATE_ROOM_STATE_EVENT: "updateRoomState",

    SOCKET_ERROR_EVENT: "socketError"
});