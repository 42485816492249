import { Container, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { API } from "../api";
import { RoomResponse } from "../types/api/Room";
import { SocketEventsEnum } from "../constants";
import socket from "../socket";
import { SpyGame } from "../components/games";
import { getUUIDFormLocalStorage } from "../utils/local-storage";
import DeleteRoom from "../components/DeleteRoom";
import UserNameModal from "../components/UserNameModal";

function Room() {
  const { id: roomId } = useParams();
  const [roomInfo, setRoomInfo] = useState<RoomResponse>();
  const [openModal, setOpenModal] = useState(true);


  const isOwner = useMemo(() => {
    const uuid = getUUIDFormLocalStorage();

    return uuid === roomInfo?.ownerId;
  }, [roomInfo?.ownerId]);


  useEffect(() => {
    console.log("socket.on useEffect firing")

    socket.on(SocketEventsEnum.UPDATE_ROOM_STATE_EVENT, (data) => {
      setRoomInfo(data);
      console.log(data);
    });
  }, []);

  useEffect(() => {
    console.log("useEffect firing")
    const init = async () => {
      const roomState = await API.room.getRoomData(roomId as string);
      console.log({roomState})
      setRoomInfo(roomState);

      // const res = await socket.emit(SocketEventsEnum.JOIN_ROOM_EVENT, roomId, "Dan");
      // console.log(res)
    }
    init();

    return () => {
      socket.disconnect();
    };
  }, [roomId]);


  const sendRoomUpdateEvent = () => {
    socket.emit(SocketEventsEnum.UPDATE_ROOM_STATE_EVENT, roomId, {
      ...roomInfo,
      data: JSON.stringify(Math.random()),
    });
  };

  const handleSubmit = async (username: string) => {
    const res = await socket.emit(SocketEventsEnum.JOIN_ROOM_EVENT, roomId, username);
    setOpenModal(false);
    console.log(res)
  };

  return (
    <Container component="main" maxWidth="xs">
      <UserNameModal 
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleSubmit={handleSubmit}
      />
      <Typography component="h1" variant="h5">
        Room Page {roomInfo?.id}
      </Typography>
      <Typography component="h1" variant="h5">
        Room Name {roomInfo?.roomName}
      </Typography>

      {isOwner && <DeleteRoom roomId={roomId as string} />}

      {roomInfo && <SpyGame roomInfo={roomInfo} roomUpdateEvent={sendRoomUpdateEvent}/>}
      
    </Container>
  );
}

export default Room;
