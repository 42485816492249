import { RoomTypes } from "../types/api/Room";

const ROOM_NAMES = [
  "Game Haven",
  "Dice Den",
  "Card Kingdom",
  "Strategy Sanctuary",
  "Tile Trek Zone",
  "Puzzle Paradise",
  "Meeple Mansion",
  "Chess Chamber",
  "Wordplay Wonderland",
  "Roleplay Realm",
  "Domino Domain",
  "Checkmate Corner",
  "Catan Corner",
  "Jigsaw Junction",
  "Ludo Lounge",
  "Quirk Quest Quarters",
  "Shuffle Shire",
  "Codenames Cove",
  "Ticket to Ride Retreat",
  "Bingo Bliss Bungalow",
];

export const getRoomRandomName = (): string => {
  return ROOM_NAMES[Math.floor(Math.random() * ROOM_NAMES.length)];
};

export const shuffleArray = <T>(array: T[]): T[] => {
  const shuffledArray = [...array];

  // Fisher-Yates shuffle algorithm
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
};

export const getRandomElementFromArray = <T>(array: T[]): T | undefined => {
  if (array.length === 0) {
    return undefined;
  }

  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
};

const RoomTypeUrlMapper = {
  Spy: "spy",
  WhoAmI: "who-am-i",
};

export const getGameUrlName = (roomType: RoomTypes) => {
  return RoomTypeUrlMapper[roomType];
};

export const getGameTypeFromUrl = (urlType: string): string => {
  return {
    spy: "Spy",
    "who-am-i": "WhoAmI",
  }[urlType] as string;
};

// export const getRoomUrlName = (str: string) => {
//   return str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
// }
