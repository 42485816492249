import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useState } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface UserNameModalProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (_: string) => void;
}

function UserNameModal({
  open,
  handleClose,
  handleSubmit,
}: UserNameModalProps) {
  const [userName, setUserName] = useState("");

  const handleUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DialogTitle>Modal Title</DialogTitle>
          <DialogContent>
            <DialogContentText>Enter your user name:</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="User Name"
              type="text"
              fullWidth
              value={userName}
              onChange={handleUserNameChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleSubmit(userName)} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Modal>
    </div>
  );
}

export default UserNameModal;
