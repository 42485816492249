import { Typography } from "@mui/material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

interface Props {
  duration: string;
}

function EstimatedDuration({ duration }: Props) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <AccessTimeOutlinedIcon
        color="primary"
        sx={{
          mr: 1,
        }}
      />
      <Typography variant="body1">{duration}</Typography>
    </div>
  );
}

export default EstimatedDuration;
