import { AppBar, Toolbar, Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../assets/logo.svg";

function Header() {
  return (
    <AppBar position="static">
      <Toolbar style={{backgroundColor: '#fff'}}>
        <Logo style={{ marginRight: "10px" }} />
        <Typography variant="h6" fontWeight="bold" style={{ textTransform: 'uppercase' }}>
          <span style={{ color: "#353535" }}>Board</span> <span style={{ color: "#2BA6FF" }}>Games</span>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
